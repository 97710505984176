import { extendTheme } from "@chakra-ui/react";
import { theme as baseTheme } from "theme";

export const theme = extendTheme(
  {
    config: {
      initialColorMode: "system",
      useSystemColorMode: false,
    },
    styles: {
      global: {
        "*": {
          scrollMarginTop: "100px",
        },
      },
    },
    components: {
      Card: {
        baseStyle: {
          card: {
            borderRadius: "xl",
          },
        },
      },
    },
  },
  baseTheme,
);

export const resetColorMode = () => {
  localStorage.removeItem("chakra-ui-color-mode");
};
